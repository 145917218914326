import React, {useState, useEffect, useLayoutEffect, forwardRef, createRef} from "react";
import "./styles/ProductsBlockSlider.css";
import "./styles/ProductsBlock.css";
import arrowLeftInActive from "./images/carousel/arrowLeftInActive.svg";
import arrowLeftActive from "./images/carousel/arrowLeftActive.svg";
import arrowRightInActive from "./images/carousel/arrowRightInActive.svg";
import arrowRightActive from "./images/carousel/arrowRightActive.svg";

export default function ProductBlockSlider(props) {
    const [width, setWidth] = useState(0);
    const [offset, setOffset] = useState(0);
    const [slideCount, setSlideCount] = useState(0);
    const [lastItem, setLastItem] = useState('left');
    const [arrowInActive, setArrowInActive] = useState('left');
    const size = props.size;

    const slideRef = createRef();
    const wrapperRef = createRef();
    const slideWindowRef = createRef();

    useEffect(() => {
        setArrowInActive('left');
        wrapperRef.current.style.left = '0px';
        setOffset(0);
    }, [props.arrowInActive])

    useEffect(() => {
        setArrowInActive(lastItem);
    }, [lastItem])

    useLayoutEffect(() => {
        const rect = slideWindowRef.current.parentNode.getBoundingClientRect();
        let sizeWidth = parseInt(rect.width);
        switch(size.width) {
            case size.width < 768 :
                sizeWidth = 270;
                break;
        }
        let currentRight = wrapperRef.current.style.left || 0;
        currentRight = parseInt(currentRight, 10);
        if(currentRight) {
            wrapperRef.current.style.left = sizeWidth*Math.round(currentRight/width)+'px';
        }
        setWidth(sizeWidth);
        setSlideCount(props.children.length);
        slideWindowRef.current.style.width = width + "px";
    }, [slideRef, offset, slideWindowRef, props]);

    const leftClick = e => {
        e.preventDefault();
        let currentRight = wrapperRef.current.style.left || 0;
        currentRight = parseInt(currentRight, 10);
        setLastItem('');
        if (-currentRight === 1 * width) {
            setLastItem('left');
        }
        if (currentRight === 0) {
            setLastItem('left');
            return false;
        }
        const newOffset = -currentRight - width;
        wrapperRef.current.style.left = -newOffset + "px";
        setOffset(newOffset);
    };

    const rightClick = e => {
        e.preventDefault();
        let currentRight = wrapperRef.current.style.left || 0;
        currentRight = parseInt(currentRight, 10);
        setLastItem('');
        if (-currentRight === (slideCount - 2) * width) {
            setLastItem('right');
        }
        if (-currentRight === (slideCount - 1) * width) {
            setLastItem('right');
            return false;
        }

        const newOffset = offset + width;
        wrapperRef.current.style.left = -newOffset + "px";
        setOffset(newOffset);
    };

    const Slide = forwardRef((props, ref) =>
        React.Children.map(props.children, child => (
            <div className="product-simple-slide" style={{width: width}}>
                {child}
            </div>
        ))
    );

    return (
        <div className="product-main-slider">
            <div className="product-slide-window" ref={slideWindowRef}>
                <div className="product-slide-wrapper" ref={wrapperRef}>
                    <Slide ref={slideRef}>{props.children}</Slide>
                </div>
            </div>
            {props.children.length > 1 ?
            <div className='product-slider-btns'>
                <button className={`slide-left ${arrowInActive === 'left' ? 'inActiveArr' : 'activeArr'}`}
                        onClick={leftClick} >
                    <img src={arrowInActive === 'left' ? arrowLeftInActive : arrowLeftActive} alt="" />
                </button>
                <button className={`slide-right ${arrowInActive === 'right' ? 'inActiveArr' : 'activeArr'}`}
                        onClick={rightClick}>
                    <img src={arrowInActive === 'right' ? arrowRightInActive : arrowRightActive} alt="" />
                </button>
            </div> : null }
        </div>
    );
}