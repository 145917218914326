import React, {useState} from 'react';
import './styles/ServicesBlock.css';
import s11 from './images/serviceBlock/s1-1.svg';
import s12 from './images/serviceBlock/s1-2.svg';
import s21 from './images/serviceBlock/s2-1.svg';
import s22 from './images/serviceBlock/s2-2.svg';
import s31 from './images/serviceBlock/s3-1.svg';
import s32 from './images/serviceBlock/s3-2.svg';
import s41 from './images/serviceBlock/s4-1.svg';
import s42 from './images/serviceBlock/s4-2.svg';
import s51 from './images/serviceBlock/s5-1.svg';
import s52 from './images/serviceBlock/s5-2.svg';
import s62 from './images/serviceBlock/s6-2.svg';
import bg22 from './images/serviceBlock/bgServices2-2.png';
import bg51 from './images/serviceBlock/bgServices5-1.png';
import bg61 from './images/serviceBlock/bgServices6-1.png';

export default function ServicesBlock( props ) {
    const items = [
        {
            key: 's1',
            block: [
                {
                    key: 's11',
                    title: ['Разработка прототипов интерфейсов (дизайн)'],
                    icon: s11,
                    bgImg: '',
                    color: 'blue',
                },
                {
                    key: 's12',
                    title: ['Разработка микросервисов на PHP под управлением Symphony'],
                    icon: s12,
                    bgImg: bg22,
                    color: 'blue',
                },
            ]
        },
        {
            key: 's2',
            block: [
                {
                    key: 's21',
                    title: ['Роботизация бизнес-процессов'],
                    icon: s21,
                    bgImg: '',
                    color: 'white',
                },
                {
                    key: 's22',
                    title: ['Мониторинг строительства', 'Продукт МонСтр'],
                    icon: s22,
                    bgImg: '',
                    color: 'white',
                },
            ]
        },
        {
            key: 's3',
            block: [
                {
                    key: 's31',
                    title: ['Мобильная разработка под Андроид'],
                    icon: s31,
                    bgImg: '',
                    color: 'white',
                },
                {
                    key: 's32',
                    title: ['Комплексная интеграция систем и программных комплексов'],
                    icon: s32,
                    bgImg: '',
                    color: 'white',
                },
            ]
        },
        {
            key: 's4',
            block: [
                {
                    key: 's41',
                    title: ['Консультации по внедрению работы СКУД'],
                    icon: '',
                    bgImg: '',
                    color: 'blue',
                },
                {
                    key: 's42',
                    title: ['Консультации по настройке бизнес-процессов и развертке СДО'],
                    icon: '',
                    bgImg: '',
                    color: 'blue',
                },
            ]
        },
        {
            key: 's5',
            block: [
                {
                    key: 's51',
                    title: ['Консультации и саппорт'],
                    icon: s51,
                    bgImg: bg51,
                    color: 'blue',
                },
                {
                    key: 's52',
                    title: ['Описание бизнес-процессов компании для будущей автоматизации'],
                    icon: s52,
                    bgImg: '',
                    color: 'blue',
                },
            ]
        },
        {
            key: 's6',
            block: [
                {
                    key: 's61',
                    title: [''],
                    icon: '',
                    bgImg: bg61,
                    color: 'white',
                },
                {
                    key: 's62',
                    title: ['Техническое обслуживание компьютерной техники и серверной инфраструктуры'],
                    icon: s62,
                    bgImg: '',
                    color: 'white',
                },
            ]
        },
    ]

    return (
        <section className="app-main-services" ref={props.refProp} >
            <div className="header-wrapper">
                <h1 className="page-header">Наши услуги</h1>
                <p className="page-description">
                    Большой опыт перевода из устаревших систем на современный стек технологии, что позволяет сохранить
                    уникальность бизнес‐процессов компании, при этом получить гибкость и скорость современных
                    технологий.
                </p>
            </div>
            <div id="servicesBlock" className="app-services-wrapper">
                {items.map(item => (
                    <div key={item.key} className={`app-services-brick-wrapper ${item.key}`}>
                        {item.block.map(block => (
                            <div key={block.key} className={`app-services-brick ${block.color}`}
                                 style={{
                                     backgroundImage: `url(${block.bgImg})`,
                                     backgroundRepeat: 'no-repeat',
                                     backgroundBlendMode: 'screen',
                                     backgroundPosition: 'right bottom',
                                 }}>
                                <div></div>
                                <div style={{ textAlign: 'right' }}><img src={block.icon} alt=""/></div>
                                <div className="app-services-brick-description">
                                    {block.title.map(title => <p>{title}</p>)}
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </section>
    );
}