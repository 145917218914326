import React, {useState, useEffect, useLayoutEffect, forwardRef, createRef} from "react";
import arrowRight from './images/appBlock/appAR.svg';
import arrowLeft from './images/appBlock/appAL.svg';
import "./styles/AppBlockSlider.css";
import "./styles/AppBlock.css";

const Slide = forwardRef((props, ref) =>
    React.Children.map(props.children, child => (
        <div className="app-simple-slide" ref={ref}>
            {child}
        </div>
    ))
);

export default function AppBlockSlider(props) {
    const [width, setWidth] = useState(0);
    const [offset, setOffset] = useState(0);
    const [slideCount, setSlideCount] = useState(0);
    const [lastItem, setLastItem] = useState('left');
    const [arrowInActive, setArrowInActive] = useState('left');
    const size = props.size;

    const slideRef = createRef();
    const wrapperRef = createRef();
    const slideWindowRef = createRef();

    useEffect(() => {
        setArrowInActive(lastItem);
    }, [lastItem])

    useLayoutEffect(() => {
        const rect = slideWindowRef.current.parentNode.getBoundingClientRect();
        let sizeWidth = parseInt(rect.width);
        setWidth(sizeWidth);
        setSlideCount(props.children.length);
        slideWindowRef.current.style.width = width + "px";
    }, [slideRef, offset, slideWindowRef, props]);

    const leftClick = e => {
        e.preventDefault();
        let currentRight = wrapperRef.current.style.right || 0;
        currentRight = parseInt(currentRight, 10);
        setLastItem('');
        if (currentRight === 1 * width) {
            setLastItem('left');
        }
        if (size.width < 1024) {
            if (currentRight >= 372 && width > 270) {
                wrapperRef.current.style.right = currentRight - 372 + "px";
                setLastItem('right');
                return false;
            }
            if (!offset) {
                setLastItem('right');
                return false;
            }
        } else {
            if (currentRight === (slideCount - 1) * width) {
                setLastItem('right');
                return false;
            }
        }

        const newOffset = currentRight - width;
        let right = size.width < 1024 ? newOffset : 0;
        wrapperRef.current.style.right = right + "px";
        setOffset(right);
    };

    const rightClick = e => {
        e.preventDefault();
        let currentRight = wrapperRef.current.style.right || 0;
        currentRight = parseInt(currentRight, 10);
        setLastItem('');
        if (currentRight === slideCount * width) {
            setLastItem('right');
        }

        if (size.width < 1024) {
            if (size.width > 768) {
                if (width > 270 && (1508 - currentRight * 2) >= 0) {
                    wrapperRef.current.style.right = currentRight + 372 + "px";
                    setLastItem('right');
                    return false;
                }
                if (1508 - currentRight * 2 < 100) {
                    setLastItem('right');
                    return false;
                }

                if (currentRight >= 372) {
                    wrapperRef.current.style.right = currentRight - 372 + "px";
                    setLastItem('right');
                    return false;
                }
                if (!offset) {
                    setLastItem('right');
                    return false;
                }
            } else {
                if (currentRight !== 0 && width > 270) {
                    setLastItem('right');
                    return false;
                }
                if (currentRight === (slideCount - 2) * width) {
                    setLastItem('right');
                }
                if (currentRight === (slideCount - 1) * width) {
                    setLastItem('right');
                    return false;
                }
            }
        } else {
            if (currentRight === (slideCount - 1) * width || offset + width >= 1508) {
                setLastItem('right');
                return false;
            }
        }

        const newOffset = offset + width;
        let right = size.width < 1024 ? newOffset : (1508 - newOffset);
        wrapperRef.current.style.right = right + "px";
        setOffset(newOffset);
    };

    return (
        <div className="app-main-slider">
            <div className="app-slide-window" ref={slideWindowRef}>
                <div className="app-slide-wrapper" ref={wrapperRef}>
                    <Slide ref={slideRef}>{props.children}</Slide>
                </div>
            </div>
            {size.width < 1700 ? <div className='app-arrows'>
                <div className='app-slide-left' onClick={leftClick} style={{ background: arrowInActive !== 'left' ? 'rgba(248, 248, 248, 0.2)' : 'none' }}>
                    <img src={arrowLeft} alt=""/>
                </div>
                <div className='app-slide-right' onClick={rightClick} style={{ background: arrowInActive !== 'right' ? 'rgba(248, 248, 248, 0.2)'  : 'none' }}>
                    <img src={arrowRight} alt=""/>
                </div>
            </div> : null
            }
        </div>
    );
}