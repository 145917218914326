import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import moment from "moment";
import './styles/App.css';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ruRU } from '@mui/x-date-pickers/locales';

const theme = createTheme(
    {
        palette: {
            primary: { main: '#1976d2' },
        },
    },
    ruRU,
);

moment.locale("ru");

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <ReactNotifications className="animated-notification"/>
            <App/>
        </ThemeProvider>
    </React.StrictMode>
);

