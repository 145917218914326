import React, {useState, useEffect} from 'react';
import './styles/ContactModal.css';
import modalIcon from './images/app/modal.svg';
import InputMask from 'react-input-mask';
import {Store} from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'
import 'react-phone-input-2/lib/style.css';
import Policy from "./files/Политика обработки персональных данных.rtf";
import {LocalizationProvider} from
        '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {MobileTimePicker} from '@mui/x-date-pickers/MobileTimePicker';
import {DesktopTimePicker} from '@mui/x-date-pickers/DesktopTimePicker';
import dayjs from "dayjs";
import "dayjs/locale/ru";
import moment from 'moment';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import checkIcon from "./images/app/checkCircle.svg";
import errorIcon from "./images/app/errorCircle.svg";

export default function ContactModal({openContactModal, modal, size}) {
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [emptyPhone, setEmptyPhone] = useState(true);
    const [callDate, setCallDate] = useState(dayjs(new Date()));
    const [callTime, setCallTime] = useState(dayjs(new Date()));
    const [disabled, setDisabled] = useState(true);
    const [permission, setPermission] = useState(false);
    const [callTimeVariants, setCallTimeVariants] = useState([
            {
                value: 'time',
                checked: false,
                label: 'Перезвоним в выбранное время.',
            },
            {
                value: 'now',
                checked: true,
                label: 'Перезвонить сейчас',
            },
        ]
    );

    useEffect(() => {
        if (!emptyPhone) {
            setDisabled(!permission);
        } else {
            setDisabled(true);
        }
    }, [permission, emptyPhone])

    const switchCallTime = (value) => {
        if (value === 'now') {
            setCallDate(dayjs(new Date()));
            setCallTime(dayjs(new Date()));
        }
        const arr = callTimeVariants.map(item => {
            item.checked = item.value === value;
            return item;
        });
        setCallTimeVariants(arr);
    }

    const validatePhone = (phone) => {
        setPhone(phone);
        if (phone.replaceAll('_', '').length === 15) {
            setEmptyPhone(false);
        } else {
            setEmptyPhone(true);
            // setErrorMessage(!mobileView ? 'Поля обязательны для заполнения' : 'Обязательно для заполнения');
        }
    }

    const submitData = async () => {
        const request = {};
        request.phone = phone;
        request.callTimeVariants = callTimeVariants.find(el => el.checked).value;
        request.callDate = moment(new Date(callDate)).format('DD-MM-YYYY');
        request.callTime = moment(new Date(callTime)).format('HH-mm');
        const result = await fetch('/api/email/send', {
            method: 'POST',
            body: JSON.stringify(request),
        }).then(res => res.json());
        const { status, message } = result;
        if (status !== false) {
            Store.addNotification({
                content: <div>
                    <img src={checkIcon} alt=""/>
                    <div>
                        <p>Ожидайте звонка</p>
                        <p>Мы свяжемся с вами в ближайшее время</p>
                    </div>
                </div>,
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                },
            });
            openContactModal(false);
            setPhone('');
            setEmail('');
            setPermission(false);
            setCallTime(null);
            setCallDate(null);
            setCallTimeVariants(callTimeVariants.map(el => {
                el.checked = el.value === 'now';
            }));
        } else {
            openContactModal(false);
            Store.addNotification({
                content: <div>
                    <img src={errorIcon} alt=""/>
                    <div>
                        <p>Заявка не отправлена</p>
                        <p>{message}</p>
                    </div>
                </div>,
                insert: "top",
                showIcon: true,
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                },
            });
        }
    }

    return (
        <div className="app-contactModal">
            <div className="app-contactModal-content" onClick={(e) => {
                e.stopPropagation()
            }}>
                <div style={{position: "relative"}}>
                <span className="app-contactModal-content-close-button"
                      onClick={() => openContactModal(false)}> &#10006; </span>
                </div>
                <div style={{position: "relative"}}>
                    <img src={modalIcon} alt={""}/>
                    <h2>{modal.title}</h2>
                    {size.width >= 768 ?
                        <span className="app-contactModal-content-subTitle">{modal.subTitle}</span> : null}
                </div>
                {modal.emailField ? <>
                    <div className="app-contactModal-input-header">EMAIL</div>
                    <input
                        type='email'
                        value={email}
                        placeholder='E-mail'
                        maxLength={40}
                        className="app-contactModal-input"
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                    />
                </> : null}
                <div className="app-contactModal-input-header">НОМЕР ТЕЛЕФОНА</div>
                <InputMask
                    mask="+7(999)999-9999"
                    maskChar='_'
                    value={phone}
                    onChange={(e) => {
                        validatePhone(e.target.value)
                    }}
                >
                    {() => <input className="app-contactModal-input"/>}
                </InputMask>
                <div className="app-contactModal-input-wrapper">
                    <div className="app-contactModal-input-item">
                        <div className="app-contactModal-input-header">ДЕНЬ</div>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
                            <DatePicker disablePast value={callDate} onChange={(newValue) => {
                                setCallDate(newValue);
                                switchCallTime('time')
                            }}/>
                        </LocalizationProvider>
                    </div>
                    <div className="app-contactModal-input-item">
                        <div className="app-contactModal-input-header">ВРЕМЯ</div>
                        {size.width > 1024 ?
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
                                <DesktopTimePicker value={callTime} ampm={false} onChange={(newValue) => {
                                    setCallTime(newValue);
                                    switchCallTime('time')
                                }}/>
                            </LocalizationProvider> :
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
                                <MobileTimePicker value={callTime} ampm={false} onChange={(newValue) => {
                                    setCallTime(newValue);
                                    switchCallTime('time')
                                }}/>
                            </LocalizationProvider>}
                    </div>
                </div>
                <div className="app-contactModal-date-wrapper">
                    {callTimeVariants.map(item => (
                        <div key={item.value}>
                            <input type="radio" name="contactTime" id={item.value} checked={item.checked}
                                   onClick={() => switchCallTime(item.value)}/>
                            <label htmlFor={item.value}>
                                <span className="app-contactModal-input-header">{item.label}</span>
                            </label>
                        </div>
                    ))}
                </div>
                <div className='app-contactModal-policy-block'>
                    <input
                        type="checkbox"
                        style={{display: "none"}}
                        id="input"
                        checked={permission}
                        onClick={() => !setPermission(!permission)}
                    />
                    <label for="input"/>
                    <span className="app-contactModal-input-header">Я согласен на&nbsp;</span>
                    <a
                        href={Policy}
                        download="Политика обработки персональных данных.rtf"
                        style={{color: 'rgba(76, 172, 233, 1)'}}
                        className="app-contactModal-input-header"
                    >обработку
                    </a>
                    <span className="app-contactModal-input-header">&nbsp;своих&nbsp;</span>
                    <span className="app-contactModal-input-header"
                          style={{marginLeft: size.width < 768 && '35px'}}>персональных данных </span>
                </div>
                <button
                    type='primary'
                    // loading={loading}
                    className="blueBtn"
                    onClick={() => submitData()}
                    disabled={disabled}
                    style={{backgroundColor: disabled ? 'grey' : 'rgba(76, 172, 233, 1)'}}
                >
                    Оставить заявку
                </button>
            </div>
        </div>
    );
}