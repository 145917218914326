import React from "react";
import downloadIcon from "./images/headerBlock/downloadIcon.svg";
import Carousel from "./Carousel";
import txc from "./images/headerBlock/TXC.png";
import emis from "./images/headerBlock/EMIS.png";
import qr from "./images/headerBlock/qr.png";
import partners from "./images/headerBlock/partners.png";
import './styles/HeaderBlock.css';

export default function HeaderBlock({openContactModal, size, refProp}) {
    const carouselItems = [
        {
            key: 'h-1',
            title: 'ТХС',
            description: 'Трансляция хода строительства',
            img: txc,
            height: size.width >= 1200 ? '256px' : '152px',
            width: size.width >= 1200 ? '350px' : 'auto',
        },
        {
            key: 'h-2',
            title: 'EMIS-DOC',
            description: 'Взаимодействие с покупателями объектов недвижимости',
            img: emis,
            height: size.width >= 1200 ? '292px' : '152px',
            width: size.width >= 1200 ? '350px' : 'auto',
        },
        {
            key: 'h-3',
            title: 'QR-поставка',
            description: 'Личный кабинет поставщика',
            img: qr,
            height: size.width >= 1200 ? '333px' : '152px',
            width: size.width >= 1200 ? '273px' : 'auto',
        },
        {
            key: 'h-4',
            title: 'CDS-партнер',
            description: 'Взаимодействие с агентами',
            img: partners,
            height: size.width >= 1200 ? '256px' : '152px',
            width: size.width >= 1200 ? '350px' : 'auto',
        },
    ];

    const modal = {
        title: 'Узнать подробнее о продукте',
        subTitle: 'Заполните форму, мы свяжемся с вами и ответим вам на все интересующие вас вопросы',
        emailField: true,
    }

    return (
        <section className='app-main-header' ref={refProp}>
            <div className='app-main-header-content'>
                <h1 id='products'>Разработка и тестирование</h1>
                <h2>уникальных IT‐решений для строительной отрасли</h2>
                <div className='app-main-header-btn-container'>
                    <button className='app-main-header-btn' onClick={() => openContactModal(true, modal)}>Узнать
                        больше
                    </button>
                    <button className='blueBtn' style={{visibility: 'hidden'}}>
                        <span>Скачать презентацию</span>
                        <img src={downloadIcon} alt=""/>
                    </button>
                </div>
                <div className='app-main-header-content-footer'>
                    <span>
                    Цель CDS Tech — централизованная разработка высокотехнологичных программных
                    продуктов для всей бизнес‐цепочки строительного бизнеса
                        </span>
                    {size.width >= 1200 ? <span>: от проектирования
                    и строительства домов до продаж и управления послепродажным сервисом.
                        </span> : <span>.</span>}
                </div>
            </div>
            <div id="headerBlock" >
                <Carousel>
                    {carouselItems.map(data => {
                        return (
                            <div key={data.key} className="carousel">
                                <div className="carousel-description">
                                    <h1>{data.title}</h1>
                                    <span>{data.description}</span>
                                </div>
                                <img src={data.img} alt='' style={{width: data.width, height: data.height}}/>
                            </div>
                        );
                    })}
                </Carousel>
            </div>
        </section>
    );
}