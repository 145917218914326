import React, {useState, useEffect, useLayoutEffect, forwardRef, createRef} from "react";
import arrowRightInActive from './images/carousel/arrowRightInActive.svg';
import arrowRightActive from './images/carousel/arrowRightActive.svg';
import arrowLeftInActive from './images/carousel/arrowLeftInActive.svg';
import arrowLeftActive from './images/carousel/arrowLeftActive.svg';
import "./styles/Carousel.css";

const Slide = forwardRef((props, ref) =>
    React.Children.map(props.children, child => (
        <div className="simple-slide" ref={ref}>
            {child}
        </div>
    ))
);

export default function Carousel(props) {
    const [width, setWidth] = useState(0);
    const [offset, setOffset] = useState(0);
    const [slideCount, setSlideCount] = useState(0);
    const [lastItem, setLastItem] = useState('left');
    const [arrowInActive, setArrowInActive] = useState('left');

    const slideRef = createRef();
    const wrapperRef = createRef();
    const slideWindowRef = createRef();

    useEffect(() => {
        setArrowInActive(lastItem);
    }, [lastItem])

    useLayoutEffect(() => {
        const rect = slideRef.current.getBoundingClientRect();
        setWidth(rect.width);
        setSlideCount(props.children.length);
        slideWindowRef.current.style.width = rect.width + "px";
    }, [slideRef, offset, slideWindowRef, props]);

    const {
        leftCallback = () => {
        }, rightCallback = () => {
        }
    } = props;

    const leftClick = e => {
        e.preventDefault();
        let currentRight = wrapperRef.current.style.right || 0;
        currentRight = parseInt(currentRight, 10);
        setLastItem('');
        if (currentRight === 1 * width) {
            setLastItem('left');
        }
        if (currentRight === 0) {
            setLastItem('left');
            return false;
        }
        const newOffset = currentRight - width;
        wrapperRef.current.style.right = newOffset + "px";
        setOffset(newOffset);
        leftCallback();
    };

    const rightClick = e => {
        e.preventDefault();
        let currentRight = wrapperRef.current.style.right || 0;
        currentRight = parseInt(currentRight, 10);
        setLastItem('');
        if (currentRight === (slideCount - 2) * width) {
            setLastItem('right');
        }
        if (currentRight === (slideCount - 1) * width) {
            setLastItem('right');
            return false;
        }

        const newOffset = offset + width;
        wrapperRef.current.style.right = newOffset + "px";
        setOffset(newOffset);
        rightCallback();
    };

    return (
        <div className="main-slider">
            <button className={`slide-left ${arrowInActive === 'left' ? 'inActiveArr' : 'activeArr'}`}
                    onClick={leftClick} >
                <img src={arrowInActive === 'left' ? arrowLeftInActive : arrowLeftActive} alt="" />
            </button>
            <div className="slide-window" ref={slideWindowRef}>
                <div className="slide-wrapper" ref={wrapperRef}>
                    <Slide ref={slideRef}>{props.children}</Slide>
                </div>
            </div>
            <button className={`slide-right ${arrowInActive === 'right' ? 'inActiveArr' : 'activeArr'}`}
                    onClick={rightClick}>
                <img src={arrowInActive === 'right' ? arrowRightInActive : arrowRightActive} alt="" />
            </button>
        </div>
    );
}