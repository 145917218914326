import React, {useState} from 'react';
import './styles/AppBlockSlider.css';
import app1 from './images/appBlock/app1.png';
import app2 from './images/appBlock/app2.png';
import app3 from './images/appBlock/app3.png';
import app4 from './images/appBlock/app4.png';
import app11add from './images/appBlock/app1-1add.svg';
import app12add from './images/appBlock/app1-2add.png';
import app2add from './images/appBlock/app2add.png';
import plusIcon from './images/appBlock/plusIcon.svg';
import AppBlockSlider from './AppBlockSlider';

export default function AppBlock({openContactModal, size}) {
    const [appItems, setAppItems] = useState([
        {
            key: 'a-1',
            title: 'Доступность документации',
            img: app1,
            animatedContent: (
                <div>
                    <div>Внедряем современные решения для эффективной работы</div>
                    <div style={{border: '0.8px solid rgba(211, 213, 212, 0.8)'}}/>
                    <div style={{display: "flex", paddingTop: '5px'}}>
                        <img src={app11add} alt=""/>
                        <span>Локализация нарушения на цифровом плане</span>
                        <img src={app12add} alt=""/>
                    </div>
                </div>
            ),
            showAnimatedContent: false,
        },
        {
            key: 'a-2',
            title: 'Статистика',
            img: app2,
            animatedContent: (
                <div>
                    <div>Наглядные и прозрачные отчёты</div>
                    <img src={app2add} alt=""/>
                </div>
            ),
            showAnimatedContent: false,
        },
        {
            key: 'a-3',
            title: 'Android, IOS, Web‐приложение',
            img: app3,
            animatedContent: <div> Работает на всех платформах, онлайн и оффлайн </div>,
            showAnimatedContent: false,
        },
        {
            key: 'a-4',
            title: 'Контроль устранения замечаний. Выдача замечаний напрямую субподрядным организациям',
            img: app4,
            animatedContent: (
                <div>
                    <div style={{marginBottom: '11px'}}>Узел примыкания кирпичной кладки к плите перекрытия выполнен с нарушением</div>
                    <div>В работе</div>
                </div>
            ),
            showAnimatedContent: false,
        },
    ]);

    const modal = {
        title: 'Заказать демонстрацию продукта',
        subTitle: 'Заполните форму, мы свяжемся с вами и ответим вам на все интересующие вас вопросы',
        emailField: true,
    }

    const showAnimatedContent = (item) => {
        const items = appItems.map(el => {
            if (item.key === el.key) {
                el.showAnimatedContent = true;
            }
            return el;
        });
        setAppItems(items);
    }

    return (
        <section className='app-main-monstrApp'>
            <div className="app-heading page-header">Приложение МонСтр</div>
            <div className="app-wrapper">
                <div>
                    <AppBlockSlider size={size}>
                        {appItems.map(item => (
                            <div key={item.key} className="app-item">
                                <img src={item.img} alt=''/>
                                <div
                                    className={item.showAnimatedContent ? 'visible animatedContent' : 'animatedContent'}>
                                    {item.animatedContent}
                                </div>
                                <div className="app-item-title">{item.title}</div>
                                <div className="app-item-description-wrapper" onClick={() => showAnimatedContent(item)}>
                                    <img src={plusIcon} alt='' className="app-item-description"/>
                                </div>
                            </div>
                        ))}
                    </AppBlockSlider>
                </div>
            </div>
            <div className="app-footer">
                <div> CDS Tech — облачное решение для контроля за строительством и эксплуатацией объектов</div>
                <button onClick={() => openContactModal(true, modal)}>Заказать демонстрацию</button>
            </div>
        </section>
    );
}