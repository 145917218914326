import React, {useState, useEffect, useRef} from 'react';
import {BrowserRouter} from 'react-router-dom';
import {HashLink as Link} from 'react-router-hash-link';
import {useWindowSize} from "@uidotdev/usehooks";
import validator from 'validator';
import HeaderBlock from './HeaderBlock.js';
import AppBlock from './AppBlock.js';
import ContactBlock from './ContactBlock.js';
import ContactModal from './ContactModal';
import ProductsBlock from './ProductsBlock.js';
import ServicesBlock from '../src/ServicesBlock.js';
import logo from './images/app/logo.png';
import logoBlack from './images/app/logoBlack.png';
import phoneIcon from './images/app/phone.svg';
import phoneIconMobile from './images/app/phoneIconMobile.svg';
import contactIcon from './images/app/contact.svg';
import Policy from '../src/files/Политика обработки персональных данных.rtf'
import pdfFile from './images/app/pdfFile.png';
import wordFile from './images/app/wordFile.png';
import './styles/App.css';
import './styles/Menu.css';

function Browser({size}) {
    return null;
}

const App = () => {

    const [contactModal, setContactModal] = useState(false);
    const [openRightMenu, setOpenRightMenu] = useState(false);
    const [modal, setModal] = useState({});
    const [showBurgerMenu, setShowBurgerMenu] = useState(false);
    const [mobileView, setMobileView] = useState(true);
    const [scroll, setScroll] = useState('');

    const headerRef = useRef(null);
    const servicesRef = useRef(null);
    const productsRef = useRef(null);
    const contactRef = useRef(null);
    const size = useWindowSize();

    useEffect(() => {
        setMobileView(size.width < 1024 ? true : false);
    }, [size]);

    useEffect(() => {
        document.body.style.overflow = contactModal ? 'hidden' : 'auto';
    }, [contactModal])

    const openContactModal = (value, modal) => {
        if (value) {
            setModal(modal);
        }
        setContactModal(value);
    }

    const handleScroll = () => {
        setScroll(window.pageYOffset > 100);
        console.log(window.pageYOffset );
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (<BrowserRouter>
        <div
            className="app"
            onClick={() => {
                if (contactModal) {
                    setContactModal(false);
                }
                if (openRightMenu) {
                    setOpenRightMenu(false);
                }
            }}>
            <header className={`app-header ${scroll && 'solid'}`}>
                <div className="app-header-wrapper">
                    {size.width < 769 ?
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <div className="menu">
                                <input type="checkbox" id="burger-checkbox" checked={showBurgerMenu} onClick={() => {setShowBurgerMenu(!showBurgerMenu)}} className="burger-checkbox"/>
                                <label htmlFor="burger-checkbox" className="burger"> </label>
                                <ul className="menu-list">
                                    <li className="menu-item" onClick={() => {
                                        setShowBurgerMenu(false);
                                        headerRef.current.scrollIntoView({behavior: "smooth"});
                                    }}> О нас </li>
                                    <li className="menu-item" onClick={() => {
                                        setShowBurgerMenu(false);
                                        productsRef.current.scrollIntoView({behavior: "smooth"});
                                    }}> Наши продукты </li>
                                    <li className="menu-item" onClick={() => {
                                        setShowBurgerMenu(false);
                                        servicesRef.current.scrollIntoView({behavior: "smooth"})
                                    }}> Услуги </li>
                                    <li className="menu-item" onClick={() => {
                                        setShowBurgerMenu(false);
                                        contactRef.current.scrollIntoView({behavior: "smooth"})
                                    }}> Контакты </li>
                                </ul>
                            </div>
                            <img
                                src={logo}
                                alt=''
                                // style={{marginTop: scroll && '7px'}}
                                onClick={() => {
                                    headerRef.current.scrollIntoView({behavior: "smooth"})
                                }}
                            />
                        </div> :
                        <img
                            src={logo}
                            alt=''
                            onClick={() => {
                                headerRef.current.scrollIntoView({behavior: "smooth"})
                            }}
                            // style={{marginTop: scroll && '7px'}}
                        />
                    }
                    {size.width >= 768 ?
                        <div className="app-header-container">
                            <div className="app-header-container-menu">
                                <div onClick={() => {
                                    headerRef.current.scrollIntoView({behavior: "smooth"})
                                }}>
                                    О нас
                                </div>
                                <span className="app-header-container-menu-divider"/>
                                <div onClick={() => {
                                    productsRef.current.scrollIntoView({behavior: "smooth"})
                                }}>
                                    Наши продукты
                                </div>
                                <span className="app-header-container-menu-divider"/>
                                <div onClick={() => {
                                    servicesRef.current.scrollIntoView({behavior: "smooth"})
                                }}>
                                    Услуги
                                </div>
                                <span className="app-header-container-menu-divider"/>
                                <div onClick={() => {
                                    contactRef.current.scrollIntoView({behavior: "smooth"})
                                }}>Контакты
                                </div>
                            </div>
                            <div className="app-header-container-contacts">
                                {size.width >= 1200 ?
                                    <a className="app-header-container-phone" href="tel:+78126760699">
                                        <img src={phoneIcon} alt={""}/>
                                        <span>+7 (812) 676-06-99</span>
                                    </a> : null
                                }
                                <button className='app-header-button' onClick={() => {
                                    openContactModal(true, {
                                        title: 'Заказать обратный звонок', subtitle: '', emailField: false,
                                    });
                                }} id="main">
                                    <span>Связаться с нами</span>
                                    <div style={{marginLeft: size.width < 1200 ? '6px' : '13px', paddingTop: '2px'}}>
                                        <img src={contactIcon} alt=""/>
                                    </div>
                                </button>
                                {mobileView && <div style={{marginTop: '8px'}}>
                                    <input type='checkbox' checked={openRightMenu} onChange={(e) => {
                                        setOpenRightMenu(!openRightMenu)
                                    }} style={{display: 'none'}} id='rightMenu'/>
                                    <label htmlFor="rightMenu"><img alt="" style={{height: '22px'}}/></label>
                                </div>}
                            </div>
                        </div> :
                        <div className="app-header-container" style={{zIndex: '20'}}>
                            <a className="app-header-container-phone" href="tel:+78126760699">
                                <span style={{textDecoration: 'underline'}}>Связаться с нами</span>
                                <img src={phoneIconMobile} alt={""} style={{marginLeft: '6px'}}/>
                            </a>
                        </div>}
                </div>
            </header>
            <main className="app-main">
                {contactModal && <ContactModal openContactModal={openContactModal} modal={modal} size={size}/>}
                <HeaderBlock refProp={headerRef} openContactModal={openContactModal} size={size}/>
                <ProductsBlock refProp={productsRef} openContactModal={openContactModal} size={size}/>
                <ServicesBlock refProp={servicesRef}/>
                <AppBlock openContactModal={openContactModal} size={size}/>
                <ContactBlock refProp={contactRef}/>
                <section className="app-main-footer">
                    <div className="app-main-footer-logo">
                        <img src={logoBlack} alt=""/>
                        <div>Все права защищены <br/> ООО "ЦДС-Тех" {new Date().getFullYear()}</div>
                    </div>
                    {size.width >= 1023 ? <div className="app-main-footer-description">
                        <p>
                            <span>Выписка из реестра аккредитованных организаций, осуществляющих деятельность в области <br/> информационных технологий (pdf - файл)</span>
                            <a
                                href='../files/%D0%92%D1%8B%D0%BF%D0%B8%D1%81%D0%BA%D0%B0_%D0%B8%D0%B7_%D1%80%D0%B5%D0%B5%D1%81%D1%82%D1%80%D0%B0_%D0%B0%D0%BA%D0%BA%D1%80%D0%B5%D0%B4%D0%B8%D1%82%D0%BE%D0%B2%D0%B0%D0%BD%D0%BD%D1%8B%D1%85_%D0%BE%D1%80%D0%B3%D0%B0%D0%BD%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D0%B9,_%D0%BE%D1%81%D1%83%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BB%D1%8F%D1%8E%D1%89%D0%B8%D1%85_%D0%B4%D0%B5%D1%8F%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D1%8C.pdf'
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src={pdfFile} alt=''/>
                            </a>
                        </p>
                        <p>
                            <span>Реквизиты ООО “ЦДС” Тех </span>
                            <a
                                href='../files/%D0%9E%D0%9E%D0%9E%20%D0%A6%D0%94%D0%A1-%D0%A2%D0%95%D0%A5.doc'
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src={wordFile} alt=''/>
                            </a>
                        </p>
                        <p> Направления деятельности </p>
                        <p style={{lineHeight: '13px'}}> ОКВЭД: 62.02деятельность консультативная и работы в
                            области
                            компьютерных технологий</p>
                        <p>
                            <span>Политика обработки персональных данных</span>
                            <a
                                href={Policy}
                                download="Политика обработки персональных данных.rtf"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src={wordFile} alt=''/>
                            </a>
                        </p>
                    </div> : null}
                </section>
            </main>
        </div>
        <Browser size={size}/>
    </BrowserRouter>)
}

export default App;
