import React, {useEffect, useState} from 'react';
import ProductBlockSlider from "./ProductBlockSlider";
import './styles/ProductsBlock.css';
import active from './images/productBlock/active.svg';
import inActive from './images/productBlock/inActive.svg';
import arrowUp from './images/productBlock/pArrowUp.svg';
import arrowDown from './images/productBlock/pArrowDown.svg';
import p11 from './images/productBlock/p1-1.png';
import p12 from './images/productBlock/p1-2.png';
import p13 from './images/productBlock/p1-3.png';
import p14 from './images/productBlock/p1-4.png';
import p21 from './images/productBlock/p2-1.png';
import p22 from './images/productBlock/p2-2.png';
import p23 from './images/productBlock/p2-3.png';
import p24 from './images/productBlock/p2-4.png';
import p31 from './images/productBlock/p3-1.png';
import p41 from './images/productBlock/p4-1.png';
import p51 from './images/productBlock/p5-1.png';
import p52 from './images/productBlock/p5-2.png';
import p61 from './images/productBlock/p6-1.png';
import p62 from './images/productBlock/p6-2.png';
import p63 from './images/productBlock/p6-3.png';
import p64 from './images/productBlock/p6-4.png';
import p71 from './images/productBlock/p7-1.png';
import p72 from './images/productBlock/p7-2.png';
import p81 from './images/productBlock/p8-1.png';
import p82 from './images/productBlock/p8-2.png';
import p83 from './images/productBlock/p8-3.png';
import p84 from './images/productBlock/p8-4.png';
import p91 from './images/productBlock/p9-1.png';
import p101 from './images/productBlock/p10-1.png';
import p102 from './images/productBlock/p10-2.png';
import p103 from './images/productBlock/p10-3.png';
import p104 from './images/productBlock/p10-4.png';
import arrowDownSlider from './images/productBlock/arrowDownPBS.svg';
import arrowDownMobSlider from './images/productBlock/arrowDownMobPBS.svg';
import arrowLeftSlider from './images/productBlock/arrowLeftPBS.svg';
import arrowLeftUpSlider from './images/productBlock/arrowLeftUp.svg';
import arrowRightSlider from './images/productBlock/arrowRightPBS.svg';
import arrowRightUpSlider from './images/productBlock/arrowRightUpPBS.svg';
import arrowUpRightSlider from './images/productBlock/arrowUpRightPBS.svg';
import arrowUpSlider from './images/productBlock/arrowUpPBS.svg';
import arrowLeftUpMob from './images/productBlock/arrowLeftUpMob.svg';
import arrowBottomLeftSlider from './images/productBlock/arrowBottomLeft.svg';

export default function ProductsBlock({openContactModal, size, refProp}) {
    const [lastItem, setLastItem] = useState('left');
    const [arrowInActive, setArrowInActive] = useState(null);
    const [items, setItems] = useState([
        {
            key: 'p-1',
            title: 'Приложение МонСтр',
            subTitle: 'Мобильное приложение по мониторингу строительства',
            img: [
                {
                    key: 'p91',
                    src: p91,
                    linkTitle: 'Подробнее на',
                    link: 'monstr.cds.spb.ru',
                    description: []
                },
            ],
            checked: true,
            bounce: false,
            previousBounce: false,
        },
        {
            key: 'p-2',
            title: 'Emis-DOC',
            mobHeight: '375px',
            img: [
                {
                    key: 'p11',
                    src: p11,
                    description: [
                        {
                            context: 'Автоматизирует создание текста договора',
                            svg: arrowDownSlider,
                            svgTablet: arrowRightSlider,
                            svgMob: arrowUpRightSlider,
                            additionalClass: 'leftTop',
                            additionalClassTab: 'rightBottom',
                            additionalClassMob: 'bottom',
                        },
                        {
                            context: 'Подготовка пакета документов для будущей сделки',
                            svg: arrowDownSlider,
                            svgTablet: arrowLeftSlider,
                            svgMob: arrowDownMobSlider,
                            additionalClass: 'leftTop',
                            additionalClassTab: 'leftBottom',
                            additionalClassMob: 'leftTop',
                        }
                    ],
                },
                {
                    key: 'p12',
                    src: p12,
                    description: [
                        {
                            context: 'Обеспечивает доступ в базу данных клиентов компании',
                            svg: arrowRightSlider,
                            svgTablet: arrowLeftSlider,
                            svgMob: arrowDownMobSlider,
                            additionalClass: 'leftBottom',
                            additionalClassTab: 'leftBottom',
                            additionalClassMob: 'leftTop',
                        },
                        {
                            context: 'Фиксирует обращения клиентов',
                            svg: arrowDownSlider,
                            svgTablet: arrowRightSlider,
                            svgMob: arrowUpRightSlider,
                            additionalClass: 'leftTop',
                            additionalClassTab: 'rightBottom',
                            additionalClassMob: 'bottom',
                        },
                    ]
                },
                {
                    key: 'p13',
                    src: p13,
                    description: [
                        {
                            context: 'Создание квартирографии объекта',
                            svg: arrowRightSlider,
                            svgTablet: arrowLeftSlider,
                            svgMob: arrowDownMobSlider,
                            additionalClass: 'leftBottom',
                            additionalClassTab: 'leftBottom',
                            additionalClassMob: 'leftTop',
                        },
                    ]
                },
                {
                    key: 'p14',
                    src: p14,
                    description: [
                        {
                            context: 'Контроль процесса передачи помещений дольщикам',
                            svg: arrowRightSlider,
                            svgTablet: arrowLeftSlider,
                            svgMob: arrowDownMobSlider,
                            additionalClass: 'leftBottom',
                            additionalClassTab: 'leftBottom',
                            additionalClassMob: 'leftTop',
                        },
                    ]
                },
            ],
            checked: false,
            bounce: false,
            previousBounce: false,
        },
        {
            key: 'p-3',
            title: 'CDS-Tenders',
            mobHeight: '409px',
            img: [
                {
                    key: 'p21',
                    src: p21,
                    description: [
                        {
                            context: 'Самостоятельная регистрация поставщиков материалов и подрядных организаций через сайт для участия в тендерах',
                            svg: arrowDownSlider,
                            svgTablet: arrowLeftSlider,
                            svgMob: arrowDownMobSlider,
                            additionalClass: 'rightTop',
                            additionalClassTab: 'leftBottom',
                            additionalClassMob: 'leftTop',
                        },
                        {
                            context: 'Информирование поставщика, в случае победы на тендере',
                            svg: arrowDownSlider,
                            svgTablet: arrowRightSlider,
                            svgMob: arrowUpRightSlider,
                            additionalClass: 'rightTop',
                            additionalClassTab: 'rightBottom',
                            additionalClassMob: 'bottom',
                        },
                    ],
                },
                {
                    key: 'p22',
                    src: p22,
                    description: [
                        {
                            context: 'Согласование и утверждение карточки поставщика и подрядчика силами Службы Безопасности',
                            svg: arrowRightSlider,
                            svgTablet: arrowLeftSlider,
                            svgMob: arrowDownMobSlider,
                            additionalClass: 'leftBottom',
                            additionalClassTab: 'leftBottom',
                            additionalClassMob: 'leftTop',
                        },
                        {
                            context: 'Сбор и формирование отчетов, сравнение предложений поставщиков и подрядчиков по тендерам',
                            svg: arrowBottomLeftSlider,
                            svgTablet: arrowRightSlider,
                            svgMob: arrowUpRightSlider,
                            additionalClass: 'bottomLeft',
                            additionalClassTab: 'rightBottom',
                            additionalClassMob: 'bottom',
                        },
                    ]
                },
                {
                    key: 'p23',
                    src: p23,
                    description: [
                        {
                            context: 'Самостоятельное внесение изменений в карточку регистрации в случае изменений данных об организации',
                            svg: arrowRightSlider,
                            svgTablet: arrowLeftSlider,
                            svgMob: arrowDownMobSlider,
                            additionalClass: 'leftBottom',
                            additionalClassTab: 'leftBottom',
                            additionalClassMob: 'leftTop',
                        },
                        {
                            context: 'Самостоятельное введение подрядчиком списка сотрудников, привязанных к договору для прохода на объект строительства',
                            svg: arrowBottomLeftSlider,
                            svgTablet: arrowRightSlider,
                            svgMob: arrowLeftUpMob,
                            additionalClass: 'bottomLeft',
                            additionalClassTab: 'rightBottom',
                            additionalClassMob: 'bottomLeftMob',
                        },
                    ]
                },
                {
                    key: 'p24',
                    src: p24,
                    description: [
                        {
                            context: 'Проведение тендеров на материалы и подрядные работы',
                            svg: arrowRightSlider,
                            svgTablet: arrowLeftSlider,
                            svgMob: arrowDownMobSlider,
                            additionalClass: 'leftBottom',
                            additionalClassTab: 'leftBottom',
                            additionalClassMob: 'leftTop',
                        },
                        {
                            context: 'Сбор и формирование отчетов, сравнение предложений поставщиков и подрядчиков по тендерам',
                            svg: arrowBottomLeftSlider,
                            svgTablet: arrowRightSlider,
                            svgMob: arrowLeftUpMob,
                            additionalClass: 'bottomLeft',
                            additionalClassTab: 'rightBottom',
                            additionalClassMob: 'bottomLeftMob',
                        },
                    ]
                },
            ],
            checked: false,
            bounce: false,
            previousBounce: false,
        },
        {
            key: 'p-4',
            title: 'CDS-Field',
            subTitle: 'Мобильное приложения для помощи в работе строителей.',
            paragraph: 'Задачи:',
            img: [
                {
                    key: 'p31',
                    src: p31,
                    description: [
                        {
                            context: 'Регистрация отпуска и прибытия машин на объекты при перемещении материала',
                            svg: arrowRightSlider,
                            svgTablet: arrowLeftSlider,
                            svgMob: arrowLeftUpMob,
                            additionalClass: 'leftBottom',
                            additionalClassTab: 'leftBottom',
                            additionalClassMob: 'bottomLeftMob',
                        },
                        {
                            context: 'Регистрация поставки материалов от поставщиков по QR кодам',
                            svg: arrowDownSlider,
                            additionalClass: 'rightTop',
                            additionalClassTab: 'hidden',
                            additionalClassMob: 'hidden',
                        },
                        {
                            context: 'Контроль за поставкой материалов от поставщиков',
                            svg: arrowDownSlider,
                            svgTablet: arrowRightSlider,
                            svgMob: arrowDownMobSlider,
                            additionalClass: 'rightTop',
                            additionalClassTab: 'rightBottom',
                            additionalClassMob: 'rightTop',
                        },
                        {
                            context: 'Согласование заявок и накладных',
                            svg: arrowDownSlider,
                            additionalClass: 'rightTop',
                            additionalClassTab: 'hidden',
                            additionalClassMob: 'hidden',
                        },
                    ],
                },
            ],
            checked: false,
            bounce: false,
            previousBounce: false,
        },
        {
            key: 'p-5',
            title: 'QR-Поставка',
            img: [
                {
                    key: 'p41',
                    src: p41,
                    description: [
                        {
                            context: 'Личный кабинет поставщика со списком плановых отгрузок, поступлений, занесенных и подписанных УПД',
                            svg: arrowRightSlider,
                            additionalClass: 'leftBottom',
                            svgTablet: arrowLeftSlider,
                            svgMob: arrowDownMobSlider,
                            additionalClassTab: 'leftBottom',
                            additionalClassMob: 'leftTop',
                        },
                        {
                            context: 'Позволяет формировать Отгрузки из заказов, печатать ТТН с QR кодом, по которому материал примут на стройке',
                            svg: arrowUpRightSlider,
                            additionalClass: 'upRight',
                            svgTablet: arrowRightSlider,
                            svgMob: arrowUpRightSlider,
                            additionalClassTab: 'rightBottom',
                            additionalClassMob: 'bottom',
                        },
                    ],
                },
            ],
            checked: false,
            bounce: false,
            previousBounce: false,
        },
        {
            key: 'p-6',
            title: 'Генератор КП',
            img: [
                {
                    key: 'p51',
                    src: p51,
                    description: [
                        {
                            context: 'Сервис формирует коммерческое предложение по шаблону для набора помещений',
                            svg: arrowRightSlider,
                            additionalClass: 'leftBottom',
                            svgMob: arrowDownMobSlider,
                            svgTablet: arrowLeftSlider,
                            additionalClassTab: 'leftBottom',
                            additionalClassMob: 'leftTop',
                        },
                    ],
                },
                {
                    key: 'p52',
                    src: p52,
                    description: [
                        {
                            context: 'КП включает в себя информацию о помещенияx, объектах, ЖК и менеджере, работающим с клиентом',
                            svg: arrowRightSlider,
                            additionalClass: 'leftBottom',
                            svgMob: arrowDownMobSlider,
                            svgTablet: arrowLeftSlider,
                            additionalClassMob: 'leftTop',
                            additionalClassTab: 'leftBottom',
                        },
                    ],
                },
            ],
            checked: false,
            bounce: false,
            previousBounce: false,
        },
        {
            key: 'p-7',
            title: 'Управление кредитованием',
            subTitle: 'Сервис для автоматизации работы кредитного специалиста',
            img: [
                {
                    key: 'p61',
                    src: p61,
                    description: [
                        {
                            context: 'Система распределения заявок с коэффициентами и мотивацией',
                            svg: arrowRightSlider,
                            additionalClass: 'leftBottom',
                            svgMob: arrowDownMobSlider,
                            svgTablet: arrowLeftSlider,
                            additionalClassMob: 'leftTop',
                            additionalClassTab: 'leftBottom',
                        },
                        {
                            context: 'Расписание работы',
                            svg: arrowRightSlider,
                            additionalClass: 'leftBottom',
                            additionalClassMob: 'hidden',
                            additionalClassTab: 'hidden',
                        },
                        {
                            context: 'Обмен информацией со смежными подразделениями в рамках подготовки сделки',
                            svg: arrowLeftUpSlider,
                            additionalClass: 'leftUp',
                            svgMob: arrowLeftUpMob,
                            svgTablet: arrowRightSlider,
                            additionalClassMob: 'bottomLeftMob',
                            additionalClassTab: 'rightBottom',
                        },
                    ],
                },
                {
                    key: 'p62',
                    src: p62,
                    description: []
                },
                {
                    key: 'p63',
                    src: p63,
                    description: [
                        {
                            context: 'Заполнение анкеты клиентов',
                            svg: arrowRightSlider,
                            additionalClass: 'leftBottom',
                            svgMob: arrowLeftUpMob,
                            svgTablet: arrowLeftSlider,
                            additionalClassMob: 'bottomLeftMob',
                            additionalClassTab: 'leftBottom',
                        },
                        {
                            context: 'Регистрация заявок от отдела продаж',
                            svg: arrowDownSlider,
                            additionalClass: 'leftTop',
                            additionalClassMob: 'hidden',
                            additionalClassTab: 'hidden',

                        },
                        {
                            context: 'Обмен заявками на ипотеку с банками (API, Почта)',
                            svg: arrowDownSlider,
                            additionalClass: 'rightTop',
                            svgMob: arrowDownMobSlider,
                            svgTablet: arrowRightSlider,
                            additionalClassMob: 'rightTop',
                            additionalClassTab: 'rightBottom',
                        },
                    ]
                },
                {
                    key: 'p64',
                    src: p64,
                    description: []
                },
            ],
            checked: false,
            bounce: false,
            previousBounce: false,
        },
        {
            key: 'p-8',
            title: 'ТХС',
            img: [
                {
                    key: 'p71',
                    src: p71,
                    description: [
                        {
                            context: 'Обеспечивает доступ в информационную систему сотрудников организации с разделением по ролям доступа',
                            svg: arrowRightSlider,
                            svgTablet: arrowLeftSlider,
                            additionalClass: 'leftBottom',
                            svgMob: arrowLeftUpMob,
                            additionalClassMob: 'bottomLeftMob',
                            additionalClassTab: 'leftBottom',
                        },
                        {
                            context: 'Камеры',
                            svg: arrowRightSlider,
                            additionalClass: 'leftBottom',
                            additionalClassMob: 'hidden',
                            additionalClassTab: 'hidden',

                        },
                        {
                            context: 'Управление нагрузкой на ресурсы',
                            svg: arrowRightSlider,
                            additionalClass: 'leftBottom',
                            additionalClassMob: 'hidden',
                            additionalClassTab: 'hidden',
                        },
                        {
                            context: 'Создание таймлапсов.',
                            svg: arrowLeftUpSlider,
                            additionalClass: 'leftUp',
                            additionalClassMob: 'hidden',
                            additionalClassTab: 'hidden',
                        },
                        {
                            context: 'Добавление камер в систему',
                            svg: arrowDownSlider,
                            svgTablet: arrowRightSlider,
                            additionalClass: 'leftTop',
                            svgMob: arrowDownMobSlider,
                            additionalClassMob: 'rightTop',
                            additionalClassTab: 'rightBottom',
                        },
                    ],
                },
                {
                    key: 'p72',
                    src: p72,
                    description: [
                        {
                            context: 'Обеспечивает доступ в информационную систему сотрудников организации с разделением по ролям доступа',
                            svg: arrowRightSlider,
                            svgTablet: arrowLeftSlider,
                            additionalClass: 'leftBottom',
                            svgMob: arrowDownMobSlider,
                            additionalClassMob: 'leftTop',
                            additionalClassTab: 'leftBottom',
                        },
                    ]
                },
            ],
            checked: false,
            bounce: false,
            previousBounce: false,
        },
        {
            key: 'p-9',
            title: 'Управление терминалами',
            img: [
                {
                    key: 'p81',
                    src: p81,
                    description: [
                        {
                            context: 'Добавления терминалов в интерфейс управления',
                            svg: arrowRightSlider,
                            svgTablet: arrowLeftSlider,
                            additionalClass: 'leftBottom',
                            svgMob: arrowLeftUpMob,
                            additionalClassMob: 'bottomLeftMob',
                            additionalClassTab: 'leftBottom',
                        },
                        {
                            context: 'Ручное администрирование терминалов',
                            svg: arrowDownSlider,
                            svgTablet: arrowRightSlider,
                            additionalClass: 'rightTop',
                            svgMob: arrowDownMobSlider,
                            additionalClassMob: 'rightTop',
                            additionalClassTab: 'rightBottom',
                        },
                    ],
                },
                {
                    key: 'p82',
                    src: p82,
                    description: [
                        {
                            context: 'Поддержка фотографий в актуальном состоянии',
                            svg: arrowLeftUpSlider,
                            svgTablet: arrowLeftSlider,
                            additionalClass: 'leftUp',
                            svgMob: arrowDownMobSlider,
                            additionalClassMob: 'rightTop',
                            additionalClassTab: 'leftBottom',
                        },
                    ]
                },
                {
                    key: 'p83',
                    src: p83,
                    description: [
                        {
                            context: 'Обеспечивает доступ в информационную систему сотрудников организации с разделением по ролям доступа',
                            svg: arrowRightSlider,
                            svgTablet: arrowLeftSlider,
                            additionalClass: 'leftBottom',
                            svgMob: arrowDownMobSlider,
                            additionalClassMob: 'leftTop',
                            additionalClassTab: 'leftBottom',
                        },
                        {
                            context: 'Актуализация списка сотрудников на терминалах',
                            svg: arrowLeftUpSlider,
                            svgTablet: arrowRightSlider,
                            additionalClass: 'leftUp',
                            svgMob: arrowLeftUpMob,
                            additionalClassMob: 'bottomLeftMob',
                            additionalClassTab: 'rightBottom',
                        },
                    ]
                },
                {
                    key: 'p84',
                    src: p84,
                    description: [
                        {
                            context: 'Обеспечивает доступ в информационную систему сотрудников организации с разделением по ролям доступа',
                            svg: arrowRightSlider,
                            svgTablet: arrowLeftSlider,
                            additionalClass: 'leftBottom',
                            svgMob: arrowDownMobSlider,
                            additionalClassMob: 'rightTop',
                            additionalClassTab: 'leftBottom',
                        },
                    ]
                },
            ],
            checked: false,
            bounce: false,
            previousBounce: false,
        },
        {
            key: 'p-10',
            title: 'CDS-Partner',
            img: [
                {
                    key: 'p101',
                    src: p101,
                    description: []
                },
                {
                    key: 'p102',
                    src: p102,
                    description: []
                },
                {
                    key: 'p103',
                    src: p103,
                    description: []
                },
                {
                    key: 'p104',
                    src: p104,
                    description: []
                },
            ],
            checked: false,
            bounce: false,
            previousBounce: false,
        },
    ]);

    const onChange = (key, value) => {
        setArrowInActive(Math.random());
        const newItems = items.map(item => {
            const newItem = {...item};
            if (newItem.checked && newItem.key !== key) {
                newItem.previousBounce = value;
                newItem.bounce = true;
            }
            newItem.checked = false;
            if (newItem.key === key) {
                newItem.checked = value;
                newItem.previousBounce = false;
                newItem.bounce = value;
            }
            return newItem;
        });
        setItems(newItems);
    }

    useEffect(() => {
        setArrowInActive(Math.random());
    }, [lastItem])

    const modal = {
        title: 'Заказать демонстрацию',
        subTitle: 'Заполните форму, мы свяжемся с вами и ответим вам на все интересующие вас вопросы',
        emailField: true,
    }

    return (
        <section className="app-main-products" ref={refProp}>
            <div className="header-wrapper">
                <h1 className="page-header">Наши продукты</h1>
                <p className="page-description">Внедряем современные решения для эффективной работы. Наши
                    продукты экономят время инженера, технического
                    надзора, облегчает работу руководителям и приносит пользу собственникам.
                </p>
            </div>
            <div className="app-main-products-wrapper">
                {items.map(item => (
                    <div key={item.key} className={`app-main-product-block-${item.checked ? 'active' : 'inActive'}`}>
                        <div
                            className={`app-main-products-radio ${item.checked ? 'active' : 'inActive'} ${item.bounce && size.width > 1023 && 'bounce'} ${item.previousBounce && size.width > 1023 && 'previousBounce'}`}>
                            <input type="checkbox" name="header" id={item.key} checked={item.checked}
                                   onClick={(e) => onChange(item.key, e.target.checked)}/>
                            <label htmlFor={item.key}>
                                <div>
                                    <img src={size.width >= 1200 ? (item.checked ? active : inActive) : active} alt=""/>
                                    <span>{item.title}</span>
                                </div>
                                {size.width < 1200 ? <img src={item.checked ? arrowUp : arrowDown} alt=""/> : null}
                            </label>
                        </div>
                        <div className="app-main-products-context" style={{
                            display: item.checked ? 'flex' : 'none',
                            height: size.width < 768 && item.mobHeight,
                        }}>
                            <div style={{position: 'absolute'}}>
                                <p>{item.subTitle}</p>
                                <p>{item.paragraph}</p>
                            </div>
                            <ProductBlockSlider size={size} arrowInActive={arrowInActive}>
                                {item.img.map(img => (
                                    <div key={img.key} className={`${img.key} app-main-products-slider-wrapper`}>
                                        <div>
                                            {img.link ?
                                                <div className={`${img.key}-link`}>
                                                    <p>{img.linkTitle}</p>
                                                    <a href={`https://${img.link}`} target="_blank">{img.link}</a>
                                                </div> : null
                                            }
                                            {img.description.map((el, i) => {
                                                let svg = el.svgMob;
                                                let additionalClass = el.additionalClassMob
                                                if (size.width >= 768 && size.width < 1025) {
                                                    svg = el.svgTablet;
                                                    additionalClass = el.additionalClassTab;
                                                }
                                                if (size.width >= 1025) {
                                                    svg = el.svg;
                                                    additionalClass = el.additionalClass;
                                                }
                                                return (
                                                    <div key={`${img.key}-${i}`}
                                                         className={`${img.key}-${i} ${additionalClass} app-main-products-slider-arrows`}>
                                                        <img src={svg} alt={""}/>
                                                        <span>{el.context}</span>
                                                    </div>
                                                )
                                            })}
                                            <img src={img.src} alt='' key={img.key}/>
                                        </div>
                                    </div>
                                ))}
                            </ProductBlockSlider>
                        </div>
                        <button className="blueBtn" style={{display: item.checked ? 'block' : 'none'}}
                                onClick={() => openContactModal(true, modal)}>
                            <span> Заказать демонстрацию </span>
                        </button>
                    </div>
                ))}
            </div>
        </section>
    );
}